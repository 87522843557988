<template>
  <div class="list">
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-cascader
        v-model="cascaderValue"
        :closeable="false"
        title="请选择疫苗接种点"
        :options="options"
        @finish="onFinish"
        :field-names="obj"
      />
    </van-popup>
    <!-- 选择社区 -->
    <div class="shade" v-if="showoverlay">
      <div class="wrapper">
        <van-radio-group
          v-model="radio"
          class="radioGroup"
          direction="horizontal"
          @change="changeCommunity"
        >
          <van-radio name="1">选择社区</van-radio>
          <van-radio name="2">填写社区</van-radio>
        </van-radio-group>
        <div class="detail">
          <van-field
            v-if="radio == '2'"
            type="textarea"
            v-model="value"
            class="input"
            placeholder="请填写社区名称"
          />
          <van-radio-group v-else v-model="radio1" @change="changeSelectList">
            <van-radio
              :name="item.id"
              v-for="item in selectList"
              :key="item.id"
              >{{ item.name }}</van-radio
            >
          </van-radio-group>
        </div>
        <div class="btn">
          <p @click="showoverlay = false">取消</p>
          <p @click="okBtn">确定</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cityList } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      cascaderValue: "",
      show: true,
      options: [],
      jsapiTicket: {},
      obj: { text: "name", value: "id", children: "sites" },
      showoverlay: false, //社区弹窗
      radio: "1", //选择社区、填写社区
      value: "", //输入框
      radio1: "", //选择社区
      radio1Value: "", //社区值
      selectList: [], //社区列表
      cityName: "", //选中的城市名称
      vaccinationSiteId: "", //选中的省id
      vaccinationSiteCityId: "", //选中的市id
      vaccinationSiteAreaId: "", //选中的区id

      channel: "", //渠道
    };
  },
  mounted() {
    if (this.$route.query.id) {
      sessionStorage.setItem("productId", this.$route.query.id);
    }
    // 如果参数上拼接有渠道号
    if (this.$route.query.channel) {
      this.channel = this.$route.query.channel;
    }
    this.cityList();
  },
  methods: {
    //
    okBtn() {
      if (this.radio == "1") {
        if (!this.radio1) {
          this.$toast({
            message: "请选择社区",
            className: "v-toast",
          });
        } else {
          this.cityName = `${this.cityName}-${this.radio1Value}`;
          sessionStorage.setItem("cityName", this.cityName);
          sessionStorage.setItem("vaccinationSiteId", this.vaccinationSiteId);
          sessionStorage.setItem(
            "vaccinationSiteCityId",
            this.vaccinationSiteCityId
          );
          sessionStorage.setItem(
            "vaccinationSiteAreaId",
            this.vaccinationSiteAreaId
          );
          sessionStorage.setItem("vaccinationSiteCommunityId", this.radio1);
          sessionStorage.setItem("vaccinationSiteCommunityName", "");
          this.$router.push({
            path: "/index",
          });
        }
      } else {
        if (this.value == "") {
          this.$toast({
            message: "请填写社区",
            className: "v-toast",
          });
        } else {
          this.cityName = `${this.cityName}-${this.value}`;
          sessionStorage.setItem("cityName", this.cityName);
          sessionStorage.setItem("vaccinationSiteId", this.vaccinationSiteId);
          sessionStorage.setItem(
            "vaccinationSiteCityId",
            this.vaccinationSiteCityId
          );
          sessionStorage.setItem(
            "vaccinationSiteAreaId",
            this.vaccinationSiteAreaId
          );
          sessionStorage.setItem("vaccinationSiteCommunityId", "");
          sessionStorage.setItem("vaccinationSiteCommunityName", this.value);
          this.$router.push({
            path: "/index",
          });
        }
      }
    },
    //选择社区方式
    changeCommunity() {
      this.value = "";
      this.radio1 = "";
    },
    // 选择社区
    changeSelectList(e) {
      this.selectList.map((item) => {
        if (item.id == e) {
          this.radio1Value = item.name;
        }
      });
    },
    //城市列表
    cityList() {
      let params = {
        lng: "116.087158",
        lat: "40.550522",
      };
      // 如果有渠道号
      if (this.channel != "") {
        params.channel = this.channel;
      }

      cityList(params).then(({ success, result }) => {
        if (success) {
          result.list.map((item) => {
            if (item.sites.length == 0) {
              delete item.sites;
            } else {
              item.sites.map((item1) => {
                if (item1.sites.length == 0) {
                  delete item1.sites;
                } else {
                  item1.sites.map((item2) => {
                    delete item2.sites;
                  });
                }
              });
            }
          });
          this.options = result.list;
        }
      });
    },
    //选择完成
    onFinish({ selectedOptions }) {
      this.vaccinationSiteId = selectedOptions[0].id;
      this.vaccinationSiteCityId = selectedOptions[1].id;
      this.vaccinationSiteAreaId = selectedOptions[2].id;
      this.cityName = selectedOptions[selectedOptions.length - 1].mergeName;
      let params = {
        lng: "116.087158",
        lat: "40.550522",
        channel: this.channel || undefined, // 渠道号
      };
      cityList(params).then(({ result: { list } }) => {
        list.forEach(({ sites }) => {
          sites.forEach(({ sites: innerSites }) => {
            const selectedSite = innerSites.find(
              ({ mergeName }) => mergeName === this.cityName
            );
            if (selectedSite) {
              this.selectList = selectedSite.sites;
            }
          });
        });
      });
      this.showoverlay = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.shade {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 5000 !important;
  .wrapper {
    position: fixed;
    z-index: 2004;
    width: 300px;
    height: 250px;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
    border-radius: 8px;
  }
}

.input {
  width: 100%;
  height: 80px;
  border: 1px solid #eaeaea;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
/deep/ .van-radio__icon--checked .van-icon {
  background-color: $primary-color;
  border-color: $primary-color;
}
/deep/ .van-radio__label {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-top: 1px solid #eaeaea;
  p {
    width: 50%;
    font-size: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p:first-child {
    color: #333333;
    border-right: 1px solid #eaeaea;
  }
  p:last-child {
    color: $primary-color;
  }
}
.radioGroup {
  padding: 20px 20px 10px;
}

.detail {
  width: 100%;
  padding: 0 20px;
  height: 150px;
  padding-top: 10px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;
  /deep/ .van-radio {
    // padding-bottom: 10px;
    height: 40px;
    // font-size: 14px;
    // line-height: 22px;
  }
  // background: red;
}
/deep/ .van-overlay {
  z-index: 99;
}
/deep/ .van-tabs__line {
  background-color: $primary-color !important;
}
/deep/ .van-cascader__option--selected {
  color: $primary-color !important;
}
</style>